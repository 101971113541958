(function () {
   "use strict";

/**
 * @typedef {{method: TFMethodName, isDefault: boolean, createdAt?: string}} TFMethodType
 * @typedef {{enabledMethods: TFMethodType[], phone: string, email: string}} TFEnableStatus
 */
angular
   .module('vdsr')
   .service('apiauth', apiAuthService);

	apiAuthService.$inject = ['$http', '$q'];

	/**
	 * @constructor
 	 * @param {import("angularjs").IHttpService} $http 
 	 * @param {import("angularjs").IQService} $q 
 	 * @param {Globals} globals 
	 */
	function apiAuthService($http, $q) {

		/**
		 * @param {string} url 
		 * @param {object} [data] - if not specified, empty object will be sent 
		 */
		function post(url, data) {
			return $http.post(url, data || {}).then((res)=>res.data.data, (res)=>{let e = res && res.data && res.data.error || 'Error occurred'; return $q.reject(e)});
		}

		let serviceApiRoot = window.stw.globals.siteUrl + '/api'+ '/twoFactorAuth';
		this.initiateApp = initiateApp;
		this.initiateEmail = initiateEmail;
		this.initiateSms = initiateSms
		this.verifyAndEnable = verifyAndEnable;
		this.verify = verify;
		this.disable = disable;
		this.sendCode = sendCode;
		this.currentStatus = currentStatus;
		this.setDefault = setDefault;
		this.getMethodInfo = getMethodInfo;
		this.getAllMethodInfo = getAllMethodInfo;
		this.post = post;

		this.methodEnabledMessage = 'You have successfully enabled two factor authentication';
		this.invalidCodeMessage = 'Invalid verification code';
	
		/**
		 * Verifies the token and then enables the auth method 
		 * @param {string} userToken 
		 * @param {string?} methodName
		 * @param {SetupData} setupData
		 * @returns {angular.IPromise<TFEnableStatus>}
		 */
		function verifyAndEnable(userToken, methodName, setupData) {
			if(setupData){
				return post('/verify-2f-and-login', {userToken, methodName, ...setupData});
			}
			return post(serviceApiRoot + '/verifyAndEnable', {userToken, methodName});
		}

		/**
		 * Verifies the token 
		 * @param {string} userToken 
		 * @param {TFMethodName} methodName
		 * @returns {angular.IPromise<void>}
		 */
		function verify(userToken, methodName) {
			return post(serviceApiRoot + '/verify', {userToken, methodName});
		}

		/**
		 * @param {string} userToken
		 * @returns {angular.IPromise<TFEnableStatus>}
		 */
		function disable(userToken, methodName) {
			return post(serviceApiRoot + '/disable', {userToken, methodName});
		}

		/**
		 * Sends a code to the given address (if none given, sends to the one on file)
		 * @param {TFMethodName} method
		 * @param {{email: string}|{phone: string}} [emailOrPhone]
		 * @returns {angular.IPromise<{codeExpirationInSeconds: number}>}
		 */
		function sendCode(method, emailOrPhone) {
			let dataToSend = method === 'email' ? {email: emailOrPhone} : {phone: emailOrPhone};
			console.log("sending data", {method, ...dataToSend})
			return post(serviceApiRoot + '/sendCode', {method, ...dataToSend});
		}

		/**
		 * Returns info regarding which 2f authentication method is enabled, if any 
		 * @returns {angular.IPromise<TFEnableStatus>}
		 */
		function currentStatus() {
			return post(serviceApiRoot + '/currentStatus');
		}

		/**
		 * @typedef {Object} SetupData
		 * @property {string} methodName
		 */

		/**
		 * @param {boolean} isDefault
		 * @param {SetupData} setupData
		 * @returns {angular.IPromise<{qrCodeImageData: string}>}
		 */
		function initiateApp(isDefault, setupData) {
			console.log("called initiateApp")
			if(setupData){
				setupData.methodName = 'app'
				return post('/mfa-setup', {isDefault, ...setupData})
			}
			return post(serviceApiRoot + '/initiate2FAppAuth', {isDefault})
		}

		/**
		 * @param {boolean} isDefault
		 * @param {SetupData} setupData
		 * @returns {angular.IPromise<{email: string, codeExpirationInSeconds: number}>}
		 */
		function initiateEmail(isDefault, setupData) {
			if(setupData){
				setupData.methodName = 'email'
				return post('/mfa-setup', {isDefault, ...setupData})
			}
			return post(serviceApiRoot + '/initiate2FEmailAuth', {isDefault})
		}

		/**
		 * @param {boolean} isDefault
		 * @param {SetupData} setupData
		 * @returns {angular.IPromise<{phone: string, codeExpirationInSeconds: number}>}
		 */
		function initiateSms(isDefault, setupData) {
			if(setupData){
				setupData.methodName = 'sms'
				return post('/mfa-setup', {isDefault, ...setupData})
			}
			return post(serviceApiRoot + '/initiate2FSmsAuth', {isDefault})
		}
		
		/**
		 * 
		 * @param {'app'|'sms'|'email'} methodName 
		 * @returns {angular.IPromise<TFEnableStatus>}
		 */
		function setDefault(methodName){
			return post(serviceApiRoot + '/setDefaultMethod', {methodName})
		}


		/** 
		* @param {TFMethodName} methodName 
		* @returns 
		*/
		function getMethodInfo(methodName){
			return methods.find(v => v.name === methodName );
		} 

		function getAllMethodInfo(){ return methods}

		let methods = [
			{
				name: 'app',
				menuLabel: 'Authenticator App', 
				bestOption: true,
				isEnabled: false,
				isDefault: false,
				disabled: () => false,
				isExpanded: false,
				isDisableExpanded: false,
				remove: { active: false },
				stepOne: "Scan this QR code with your app on your phone",
				stepTwo: 'Enter the six-digit code from your Authenticator App',
				helpText: [`Use an authenticator app of your choice on your phone to get two-factor authentication codes when prompted.`,'Some well-known options are Microsoft Authenticator and Google Authenticator']
			},
			{
				name: 'email',
				menuLabel: 'Email Message',
				isEnabled: false,
				isDefault: false,
				disabled: () => false,
				isExpanded: false,
				isDisableExpanded: false,
				stepOne: "Send validation via email.",
				stepTwo: 'Enter the six-digit code that we have just sent to your email',
				helpText: ['Use your profile email address to get the authentication codes']
			},
			{
				name: 'sms',
				menuLabel: 'SMS Message',
				//disabled: () => !hasPhone(), 
				disabledLabel: 'Please add phone # to your profile to use this method', 
				isEnabled: false,
				isDefault: false,
				isExpanded: false,
				isDisableExpanded: false,
				stepOne: "Send validation via text message.",
				stepTwo: 'Enter the six-digit code that we have just sent to your phone',
				helpText: ['Use your profile phone number to get the authentication codes via sms']
			}
		];
	}


})();